import profile from './188_1480808926847.jpeg';
import upArrow from './up-arrow-png_302932.png'
import './App.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollNavigation from 'react-single-page-navigation';


function App() {
  return (

    <ScrollNavigation elements={{
      TopOfPage: {}, Profile: {}, TechnicalSkills: {}, SoftSkills: {}, Education: {},
      TSS: {}, HRB: {}, NFN: {}, Shaw: {}
    }}>
      {({ refs, activeElement, goTo }) => (
        <div>
          <Navbar fixed="top" ref ={refs.TopOfPage} className="nav" bg="light" expand="lg">
            <Container>
              <Navbar.Brand href="#home">
                <div onClick={() => goTo('TopOfPage')}>
                  Ahmed Jama
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link>
                    <div onClick={() => goTo('Profile')}>
                      Profile
                    </div>
                  </Nav.Link>
                  <Nav.Link>
                    <div onClick={() => goTo('TechnicalSkills')}>
                      Technical Skills
                    </div>
                  </Nav.Link>
                  <Nav.Link>
                    <div onClick={() => goTo('SoftSkills')}>
                      Soft Skills
                    </div>
                  </Nav.Link>
                  <NavDropdown title="Professional Experience" id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={() => goTo('TSS')}>The Solution Stack</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => goTo('HRB')}>H&R Block</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => goTo('NFN')}>Ninety Five Noir</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => goTo('Shaw')}>Shaw Communications</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link>
                    <div onClick={() => goTo('Education')}>
                      Education
                    </div>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <div className="App" ref={refs.TopOfPage} >
            <header className="App-header">
              <img className="App-logo" src={profile} alt="That's me" />
              <div>
                <h1>Ahmed Jama</h1>
              </div>
              <div>
                <a href="https://www.linkedin.com/in/ahmed-jama-8b1819a8/" target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 50 50" width="50px" height="50px">    <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z" /></svg>
                </a>
                <a href="https://github.com/ahmedjama23" target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 64 64" width="50px" height="50px"><path d="M32 6C17.641 6 6 17.641 6 32c0 12.277 8.512 22.56 19.955 25.286-.592-.141-1.179-.299-1.755-.479V50.85c0 0-.975.325-2.275.325-3.637 0-5.148-3.245-5.525-4.875-.229-.993-.827-1.934-1.469-2.509-.767-.684-1.126-.686-1.131-.92-.01-.491.658-.471.975-.471 1.625 0 2.857 1.729 3.429 2.623 1.417 2.207 2.938 2.577 3.721 2.577.975 0 1.817-.146 2.397-.426.268-1.888 1.108-3.57 2.478-4.774-6.097-1.219-10.4-4.716-10.4-10.4 0-2.928 1.175-5.619 3.133-7.792C19.333 23.641 19 22.494 19 20.625c0-1.235.086-2.751.65-4.225 0 0 3.708.026 7.205 3.338C28.469 19.268 30.196 19 32 19s3.531.268 5.145.738c3.497-3.312 7.205-3.338 7.205-3.338.567 1.474.65 2.99.65 4.225 0 2.015-.268 3.19-.432 3.697C46.466 26.475 47.6 29.124 47.6 32c0 5.684-4.303 9.181-10.4 10.4 1.628 1.43 2.6 3.513 2.6 5.85v8.557c-.576.181-1.162.338-1.755.479C49.488 54.56 58 44.277 58 32 58 17.641 46.359 6 32 6zM33.813 57.93C33.214 57.972 32.61 58 32 58 32.61 58 33.213 57.971 33.813 57.93zM37.786 57.346c-1.164.265-2.357.451-3.575.554C35.429 57.797 36.622 57.61 37.786 57.346zM32 58c-.61 0-1.214-.028-1.83-.07C30.787 57.971 31.39 58 32 58zM29.788 57.9c-1.217-.103-2.411-.289-3.574-.554C27.378 57.61 28.571 57.797 29.788 57.9z" /></svg>
                </a>
              </div>
            </header>
            <body className="App-body">
            <hr  ref={refs.Profile} />
              <div className="sectionHeader">Profile</div>
              <div className="section">
                <div className="sectionContent">
                  Currently, I am a software developer at The Solution Stack, a consulting firm providing professional services, custom-developed solutions and products to a number of clients of varying sizes. In my role, I have worked on developing products internally at The Solution Stack and worked with clients as a consultant in a variety of positions ranging from custom Python scripting to enterprise database development. In my unique position, I have been instrumental in delivering success for clients and The Solution Stack as a developer while providing value to other projects and clients outside of my purview. My current goal is to further my aptitude in server-side and front-end development as a software developer and expand my skill set. As a software developer in a consulting position, I am regularly placed into new teams and environments with the expectation of rapidly contributing in a meaningful way and developing the relationships needed to ensure collaborative success. As such, I am effective at working in a team or independently and exceptional at communicating and cooperating to succeed together.
                </div>
              </div>

              <hr ref={refs.TechnicalSkills} />

              <div className="section" >
                <div className="sectionHeader">Technical Skills</div>
                <div className="sectionContent">
                  <table className="techTableFullSize">
                    <tr>
                      <th><u>Languages</u></th>
                      <th><u>Technologies</u></th>
                    </tr>
                    <tr>
                      <td>
                        <table className="languages">
                          <tr>
                            <td>
                              <ul>
                                <li>JavaScript / TypeScript</li>
                                <li>Python</li>
                                <li>C#</li>
                                <li>Java</li>
                                <li>Microsoft SQL / PostgreSQL</li>
                              </ul>
                            </td>
                            <td>
                              <ul>
                                <li>React</li>
                                <li>Node.js</li>
                                <li>HTML</li>
                                <li>CSS</li>
                                <li>ASP.NET Core</li>
                              </ul>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td>

                        <table className="technologies">
                          <tr>
                            <td>
                              <ul>
                                <li>Power BI</li>
                                <li>Tableau</li>
                                <li>SSMS</li>
                                <li>JIRA</li>
                              </ul>
                            </td>
                            <td>
                              <ul>
                                <li>Azure DevOps</li>
                                <li>ArcGIS Pro</li>
                                <li>ElasticSearch</li>
                                <li>Virtual Machines</li>
                              </ul>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <table className="techTableMobile">
                    <tr>
                      <th><u>Languages</u></th>
                      <th><u>Technologies</u></th>
                    </tr>
                    <tr>
                      <td>
                        <table className="languages">
                          <tr>
                            <td>
                              <ul>
                                <li>JavaScript</li>
                                <li>TypeScript</li>
                                <li>Python</li>
                                <li>C#</li>
                                <li>Java</li>
                                <li>PostgreSQL</li>
                                <li>Microsoft SQL</li>
                                <li>React</li>
                                <li>Node.js</li>
                                <li>HTML</li>
                                <li>CSS</li>
                                <li>ASP.NET Core</li>
                              </ul>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td>

                        <table className="technologies">
                          <tr>
                            <td>
                              <ul>
                                <li>Power BI</li>
                                <li>Tableau</li>
                                <li>SSMS</li>
                                <li>JIRA</li>
                                <li>Azure DevOps</li>
                                <li>ArcGIS Pro</li>
                                <li>ElasticSearch</li>
                                <li>Virtual Machines</li>
                              </ul>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <hr ref={refs.SoftSkills} />

              <div className="sectionHeader" >Soft Skills</div>
              <div className="section">
                <ul className="sectionContent">
                  <li><b>Creative Problem Solving</b> - Able to quickly determine alternative solutions to a problem that can be rapidly prototyped to stimulate iterative development.</li>

                  <li><b>Highly Adaptive</b> - Quick to understand new environments or challenges and provide value within a team or project.</li>

                  <li><b>Client-Centric Attitude</b> - Focused on the needs of the user or client when gathering requirements and making necessary assumptions to ensure success.</li>

                  <li><b>Proficient Presenter & Communicator</b> - Experienced in demonstrating or presentation to stakeholders and able to effectively explain technical details at the required level of detail based on the audience.</li>

                  <li><b>Effective Time-Management & Task Prioritization</b> - Able to accurately determine priority order of concurrent tasks and allocate time and effort across multiple projects to ensure the greatest possible chance of success in a dynamic environment.</li>
                </ul>
              </div>
              
              <hr ref={refs.TSS} />

              <div className="sectionHeader" >Professional Experience</div>
              <div className="section">
                <div>
                  <div className="subheader" >The Solution Stack<span> - Software Developer</span></div>
                  <div className="date">FEBRUARY 2020 - PRESENT</div>
                  <div className="sectionContent">
                    <p>
                      Currently, I am working with a client to develop a solution to allow them to proactively recognize and remedy potential risk through intelligent reporting and analysis using custom algorithms and a unified, central data source that we developed earlier in the project, resulting in millions saved within 5 years. Previously, I was the primary developer for TSS Maps for Power BI, a custom Power BI visual using TypeScript and the ArcGIS API to fill a gap in the GIS technology space. I am still involved in troubleshooting production issues and development of new features in a limited capacity. Furthermore, I was heavily involved in a project for a client that required a way to quickly visualize IoT data from autonomous vehicles which provided millions of records per day. This project succeeded by providing the client with a new perspective on data analysis and by rapidly prototyping different technologies to find the ideal combination.
                    </p>
                    <div><u>Key Accomplishments</u></div>
                    <ul>
                      <li>Developed a custom application that has been purchased by multiple enterprises with resounding success and positive feedback.</li>
                      <li>Provided multiple clients with enterprise level solutions that are currently in use and providing value.</li>
                      <li>Constantly collaborating and communicating with clients to demonstrate progress and value as well as receive client input to ensure the overall success of projects.</li>
                    </ul>
                  </div>
                </div>

                <hr className="jobDivider" ref={refs.HRB}/>

                <div>
                  <div className="subheader" >H&R Block<span> - Digital Product Analyst</span></div>
                  <div className="date">SEPTEMBER 2016 - FEBRUARY 2020</div>
                  <div className="sectionContent">
                    <p>
                      As a product analyst, I was responsible for providing business insights from prepared data to several different departments pertaining to Canadian digital products. This included marketing data, financial and accounting figures, and key performance indicators for managers, directors and executives. My role quickly expanded into preparing the data for these reports and performing ad hoc analysis to answer specific business questions as well as providing data to the software development team to track adoption and success of features. Furthermore, I performed quality assurance for data developed by other analysts and developers and gained experience in end-to-end reporting by curating data from initial raw data to cleansed reporting tables and into Tableau reports for consumption.
                    </p>
                    <div><u>Key Accomplishments</u></div>
                    <ul>
                      <li>Developed and maintained scheduled business insights reports that were used to make crucial, data-driven decisions that lead to year-over-year success.</li>
                      <li>Performed ad hoc data analysis for different business units and presented the resulting insights to stakeholders which influenced internal processes.</li>
                      <li>Provided value to the software development team by finding and reporting on issues through data quality tests and effective cooperation.</li>
                      <li>Grew into a larger role and set of responsibilities including being the primary database developer and analyst for a time and gaining invaluable experience in database management.</li>
                    </ul>
                  </div>
                </div>

                <hr className="jobDivider" ref={refs.NFN} />

                <div>
                  <div className="subheader" >Ninety Five Noir<span> - Freelance Web Developer</span></div>
                  <div className="date">DECEMBER 2015 - NOVEMBER 2016</div>
                  <div className="sectionContent">
                    <p>
                      While I was attending university, I developed a website for a client using an iterative development methodology. I would regularly meet with the client to prototype designs and layouts for different pages including a landing page, photo gallery and additional informational pages such as terms of service and a privacy policy. After gathering requirements and feedback, I would rapidly develop a high fidelity prototype and review with the client and improve aspects until a production-ready version was developed and used for the final website.
                    </p>
                    <div><u>Key Accomplishments</u></div>
                    <ul>
                      <li>Learning the value of a client-centric approach to developing and ensuring every step taken was in the best interest of the client.</li>
                      <li>Designing a multi-page website without the use of existing frameworks like Bootstrap and learning the fundamentals of web design through practical experience.</li>
                      <li>Employing an iterative development methodology to frequently demonstrate value to the client and correct potential issues at the time they arose as opposed to having the client find issues with the final product.</li>
                    </ul>
                  </div>
                </div>

                <hr className="jobDivider" ref={refs.Shaw} />

                <div>
                  <div className="subheader" >Shaw Communications<span> - Customer Solutions Expert</span></div>
                  <div className="date">JANUARY 2016 - SEPTEMBER 2016</div>
                  <div className="sectionContent">
                    <p>
                      As a Customer Solutions Expert, my primary responsibility was to call potential customers and convert them to actual customers. I found success in this role by staying up to date on current promotions and using them to maximize customer savings. Additionally, I would regularly be faced with objections and challenges that I would have to overcome through active listening and providing creative solutions to ensure the potential customer was comfortable with switching providers.
                    </p>
                    <div><u>Key Accomplishments</u></div>
                    <ul>
                      <li>Used different combinations of promotions that were regularly changing leading to dynamic interactions with every potential customer.</li>
                      <li>Learned effective strategies in overcoming objections and recognizing subtle indications of customer interests to efficiently change the minds of dismissive or negatively minded customers</li>
                      <li>Scheduled the setup of services for interested customers and followed up to confirm customers were satisfied with their service and adapted to unexpected scheduling conflicts that would occur.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <hr ref={refs.Education} />

              <div className="sectionHeader" >Education</div>
              <div className="section">
                <div className="subheader">
                  Mount Royal University, Calgary, AB<span> - Computer Information Systems</span>
                </div>
                <div className="date">SEPTEMBER 2013 - APRIL 2019</div>
              </div>
            </body>
          </div>
        </div>
      )}
    </ScrollNavigation>
  );
}

export default App;
